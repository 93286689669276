import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import router from '../router';
import { Menu, MenuItem } from '@mui/material';
import { useMutation, gql } from "@apollo/client";
import { MUIWrapperContext } from './MUIWrapper';
import { useTranslation } from 'react-i18next';

const LOGOUT = gql`
	mutation Logout {
		logout(input: {}) {
			status
		}
	}
`;

function DashboardBar() {
  const { t } = useTranslation();

  const [logout, { loading }] = useMutation(LOGOUT);
  const { countryFlag, toggleLanguage} = React.useContext(MUIWrapperContext);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const settings = [
    {
      name: t('user_menu.my_account'),
      action: () => router.navigate('/my-account')
    },
    {
      name: t('user_menu.logout'),
      action: () => {
        logout()
          .then(() => {
            router.navigate('/');
          })
          .catch(error => {
            console.error('Error:', error);
          });
      }
    }
  ];

  return (
    <AppBar position="static" style={{background: '#efefef'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <a href="/dashboard">
            <Box 
              component="img"
              src="/logo.png"
              alt="logo"
              sx={{
                width: {
                  xs: '100px',
                  md: '150px'
                }
              }}
            />
          </a>
          

          <Box
            sx={{
              flexGrow: 1,
              marginLeft: {
                sx: '15px',
                md: '30px'
              }
            }}
          >
            <Typography 
              component="a" 
              variant="h3" 
              sx={{
                color: '#4581ff',
                textDecoration: 'none' ,
                fontSize: {
                  xs: '2rem',
                  md: '3rem'
                }
              }} 
              href="/dashboard">
              Portal
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton 
              onClick={() => {
                toggleLanguage();
              }} 
            >
              <img 
                src={countryFlag} 
                alt="country-flag"
                style={{width: '30px', height: '20px', borderRadius: '5px'}}
              />
            </IconButton>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar />
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting.name} onClick={setting.action}>
                    <Typography textAlign="center">{setting.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default DashboardBar;