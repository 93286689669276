
const design_types = [
    {
        key: 'design_type',
        hint: 'services.hints.anatomical_or_cutback',
        type: 'text',
    },
    {
        key: 'design_type',
        hint: 'services.hints.open_or_closed',
        type: 'text',
    },
    {
        key: 'design_type',
        hint: 'services.hints.implant_model',
        type: 'text',
    },
]

const materials = [
    {
        key: 'material',
        hint: 'services.hints.zirconium',
        type: 'text',
    },
]

const implant_types = [
    {
        key: 'implant_type',
        hint: 'services.hints.ref_number',
        type: 'text',
    },
]

const digital_3d_printed_model = {
    key: 'printed_model',
    type: 'switch',
    tooltip: true,
}

const OrderFields: any = {
    digital_design: {
        name: 'Digital Design',
        types: {
            crown: {
                name: 'Crown',
                fields: [
                    design_types[0],
                    materials[0],
                    digital_3d_printed_model
                ]
            },
            temporary_crown: {
                name: 'Temporary Crown',
                fields: [
                    materials[0],
                    digital_3d_printed_model
                ]
            },
            veneers: {
                name: 'Veneers',
                fields: [
                    design_types[0],
                    materials[0],
                    digital_3d_printed_model
                ]
            },
            inlay_onlay: {
                name: 'Inlay/Onlay',
                fields: [
                    materials[0],
                    digital_3d_printed_model
                ]
            },
            bridge: {
                name: 'Bridge',
                fields: [
                    design_types[0],
                    materials[0],
                    digital_3d_printed_model
                ]
            },
            temporary_bridge: {
                name: 'Temporary Bridge',
                fields: [
                    materials[0],
                    digital_3d_printed_model
                ]
            },
            cantilever_bridge: {
                name: 'Cantilever Bridge',
                fields: [
                    design_types[0],
                    materials[0],
                    digital_3d_printed_model
                ]
            },
            maryland_bridge: {
                name: 'Maryland Bridge',
                fields: [
                    design_types[0],
                    materials[0],
                    digital_3d_printed_model
                ]
            },
            full_arch: {
                name: 'Full Arch',
                fields: [
                    design_types[0],
                    materials[0],
                    digital_3d_printed_model
                ]
            },
            implant_crown: {
                name: 'Implant Crown',
                fields: [
                    design_types[0],
                    materials[0],
                    implant_types[0],
                    digital_3d_printed_model
                ]
            },
            implant_bridge: {
                name: 'Implant Bridge',
                fields: [
                    design_types[0],
                    materials[0],
                    implant_types[0],
                    digital_3d_printed_model
                ]
            },
            all_on_x: {
                name: 'All on X',
                fields: [
                    design_types[0],
                    materials[0],
                    implant_types[0],
                ]
            },
            smile_design: {
                name: 'Smile Design',
                fields: [
                    digital_3d_printed_model
                ]
            },
            digital_wax_up: {
                name: 'Digital Wax-up',
                fields: [
                    digital_3d_printed_model
                ]
            },
            design_customized_tray: {
                name: 'Design Customized Tray',
                fields: [
                    design_types[1],
                    digital_3d_printed_model
                ]
            },
            design_model: {
                name: 'Design Model',
                fields: [
                    design_types[2],
                    {
                        key: 'implant_model',
                        type: 'switch',
                    },
                    digital_3d_printed_model
                ]
            },
            other: {
                name: 'Other',
                fields: []
            },
        }
    },
    printing: {
        name: '3D Printing',
        types: {
            printed_model: {
                name: '3D Printed Model',
                fields: [
                    {
                        key: 'design',
                        type: 'switch'

                    },
                    {
                        key: 'removable_die',
                        type: 'switch'
                    }
                ]
            },
            crown_bridge_model: {
                name: 'Crown and Bridge Model',
                fields: [
                    {
                        key: 'design',
                        type: 'switch'
                    },
                    {
                        key: 'removable_die',
                        type: 'switch'
                    }
                ]
            },
            digital_wax_up_model: {
                name: 'Digital Wax-Up Model',
                fields: [
                    {
                        key: 'design',
                        type: 'switch'
                    },
                ]
            },
            implant_model: {
                name: 'Implant Model',
                fields: [
                    {
                        key: 'design',
                        type: 'switch'
                    },
                    {
                        key: 'removable_die',
                        type: 'switch'
                    },
                    {
                        key: 'gingiva',
                        type: 'switch'
                    },
                    {
                        key: 'analog',
                        type: 'switch'
                    },
                ]
            },
            diagnostic_models: {
                name: 'Diagnostic Models',
                fields: [
                    {
                        key: 'design',
                        type: 'switch'
                    },
                ]
            },
            customized_tray: {
                name: 'Customized Tray',
                fields: [
                    {
                        key: 'design',
                        type: 'switch'
                    },
                ]
            },
            surgical_guide: {
                name: 'Surgical Guide',
                fields: []
            },
            splint: {
                name: 'Splint',
                fields: []
            },
            dental_test_model: {
                name: 'Dental Test Model',
                fields: [
                    {
                        key: 'design',
                        type: 'switch'
                    },
                ]
            },
            other: {
                name: 'Other',
                fields: []
            },
        }
    }
};

const FieldKeys = [
    'design_type',
    'material',
    'implant_type',
    'printed_model',
    'implant_model',
    'removable_die',
    'gingiva',
    'analog',
    'design',
]

export default OrderFields;

export const DigitalDesignFields = OrderFields.digital_design.types;
export const PrintingFields = OrderFields.printing.types;
export const FieldKeysList = FieldKeys;