import { Dialog, TextField, Button, DialogActions, DialogContent, DialogTitle, Typography, Grid, List, ListItem, ListItemText, ListItemButton, FormControlLabel, Switch, CircularProgress, Tooltip, Box, DialogProps } from "@mui/material";
import { IconButton } from '@mui/material';
import { Info, Delete } from "@mui/icons-material";

import { DigitalDesignFields, PrintingFields } from "../constants/OrderFields";
import { useEffect, useState } from "react";
import React from "react";
import { useDropzone } from "react-dropzone";
import useAuth, { User } from "../hooks/useAuth";
import { useTranslation } from "react-i18next";


const toHumanReadable = (str: string) => {
    return str.replace(/_/g, ' ')
              .replace(/\b\w/g, char => char.toUpperCase());
};

interface OrderDialogProps {
	isEditDialogOpen: boolean;
	closeDialog: DialogProps["onClose"];
	selectedRow: any;
	formData: any;
	setFormData: any;
}

export function OrderDialog(props: OrderDialogProps) {
	const { t } = useTranslation();

	const { isEditDialogOpen, closeDialog, selectedRow, formData, setFormData } = props;
	const [step, setStep] = useState(1);
	const [selectedItem, setSelectedItem] = useState<string | null>(null);
	const [selectedFields, setSelectedFields] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);
	const [loadingText, setLoadingText] = useState("")
	const [confirmationMessage, setConfirmationMessage] = useState<string | null>(null);
	const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
	const [borderColor, setBorderColor] = useState("#cccccc")

	const { user } = useAuth();
	const { databaseId, email } = user as User;

	useEffect(() => {
		if (!isEditDialogOpen) {
			setStep(1);
			setSelectedItem(null);
			setSelectedFields([]);
			setFormData({});
			setUploadedFiles([]);
		}
	}, [isEditDialogOpen, setFormData]);

	const handleCategoryClick = (category: string) => {
		setFormData({ ...formData, category });
		setStep(2);
	};

	const handleListItemClick = (item: string) => {
		const fields = formData.category === 'Digital Dental Design' ? DigitalDesignFields[item].fields : PrintingFields[item].fields;
		const itemName = formData.category === 'Digital Dental Design' ? t('services.digital_design.' + item) : t('services.print_service.' + item)
		setSelectedItem(itemName);
		setSelectedFields(fields);
		setFormData({ ...formData, product: item });
		setStep(3);
	};

	const handleBack = () => {
		if (step - 1 === 2) {
			setSelectedItem(null);
			setUploadedFiles([]);
			
			// clear the form data
			const newData = { ...formData };
			for (const field of selectedFields) {
				newData[field.key] = '';
			}

			newData.extra_info = '';

			setFormData(newData);
		}
		setStep(step - 1);
	};

	const handleFieldChange = (key: string, value: any) => {
		setFormData({ ...formData, [key]: value });
	};

    const handleRemoveFile = (file: any) => {
        setUploadedFiles(uploadedFiles.filter((f) => f !== file));
    };

	const uploadFile = async (file: string | Blob, credentials: any) => {
		const formData = new FormData();
		formData.append('file', file);
	
		const response = await fetch('https://backend.identaldesigner.nl/wp-json/wp/v2/media', {
			method: 'POST',
			headers: {
				'Authorization': `Basic ${credentials}`
			},
			body: formData
		});
	
		if (!response.ok) {
			throw new Error('File upload failed');
		}
	
		const data = await response.json();
		return data.id; // Return the media ID
	};
	

    const handleSubmit = async () => {
		if (uploadedFiles.length === 0) {
			setBorderColor("#ff2020")
			return;
		}

		setLoadingText(t('pages.order_dialog.uploading_files'))
		setLoading(true);
		setBorderColor("#cccccc")
    
        const username = 'admin';
        const password = '6hgN Qssm LVh3 yzZq T2KT 9diR';
		const credentials = btoa(`${username}:${password}`);

		try {
			// Upload files and get their media IDs
			const mediaIds = await Promise.all(uploadedFiles.map(file => uploadFile(file, credentials)));

			setLoadingText(t('pages.order_dialog.sending_order'))
	
			// Prepare order data with media IDs
			const orderData = {
				title: `${email} - ${selectedItem}`,
				author: databaseId,
				status: 'publish',
				acf: {
					service_type: formData.category,
					order_type: selectedItem,
					extra_info: formData.extra_info || '',
					status: "processing",
					files: mediaIds, // Include the media IDs
					// Add other ACF fields from formData
					...Object.keys(formData).reduce((acc: any, key) => {
						if (key !== 'extra_info' && key !== 'product' && key !== 'category') {
							acc[key] = String(formData[key]);
						}
						return acc;
					}, {})
				}
			};
	
			// Create the order
			const response = await fetch('https://backend.identaldesigner.nl/wp-json/wp/v2/custom_order', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Basic ${credentials}`
				},
				body: JSON.stringify(orderData)
			});
	
			if (response.ok) {
				setConfirmationMessage(t('pages.order_dialog.order_confirmed'));
				setStep(4);
			} else {
				const errorData = await response.json();
				setConfirmationMessage(`${t('pages.order_dialog.order_error')}: ${errorData.message}`);
				setStep(4);
			}
		} catch (error) {
			setConfirmationMessage(t('pages.order_dialog.order_error'));
			setStep(4);
		} finally {
			setLoading(false);
		}
    };
    

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			'image/*': ['.png', '.jpeg', '.jpg', '.gif'],
			'application/ply': ['.ply'],
			'image/dicom': ['.dcm'],
			'application/sla': ['.stl'],
		},
		onDrop: (acceptedFiles) => {
			setBorderColor("#cccccc")
			setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
		},
	});

	const isFormValid = () => {
		for (const field of selectedFields) {
			if (field.type !== 'switch' && field.key !== 'extra_info' && !formData[field.key]) {
				return false;
			}
			if (field.key === 'analog' && formData['analog'] && !formData['reference_number']) {
				return false;
			}
		}
		return true;
	};

	return (
		<Dialog
			open={isEditDialogOpen}
			onClose={closeDialog}
			fullWidth
			PaperProps={{ style: {
				minHeight: '60vh',
				maxHeight: '60vh',
			}}}
		>
			<DialogTitle>
				{t('pages.order_dialog.title')}
			</DialogTitle>
			<DialogContent dividers>
				{step === 1 && (
					<>
						<Typography variant="subtitle1" gutterBottom>
							{t('pages.order_dialog.select_category')}
						</Typography>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6}>
								<Button
									variant="outlined"
									fullWidth
									onClick={() => handleCategoryClick('Digital Dental Design')}
								>
									{t('services.digital_design_label')}
								</Button>
							</Grid>
							<Grid item xs={12} md={6}>
								<Button
									variant="outlined"
									fullWidth
									onClick={() => handleCategoryClick('Dental 3D Printing')}
								>
									{t('services.3d_printing_label')}
								</Button>
							</Grid>
						</Grid>
					</>
				)}
				{step === 2 && (
					<>
						<Typography variant="subtitle1" gutterBottom>
							{t('pages.order_dialog.select_product')}
						</Typography>
						<List dense>
							{(formData.category === 'Digital Dental Design' ? Object.keys(DigitalDesignFields) : Object.keys(PrintingFields)).map((item, index) => (
								<ListItemButton
									key={index}
									selected={selectedItem === (formData.category === 'Digital Dental Design' ? t('services.digital_design.' + item) : t('services.print_service.' + item))}
									onClick={() => handleListItemClick(item)}
								>
									<ListItemText 
										primary={formData.category === 'Digital Dental Design' ? t('services.digital_design.' + item) : t('services.print_service.' + item)}
										onClick={() => handleListItemClick(item)} 
									/>
								</ListItemButton>
							))}
						</List>
					</>
				)}
				{step === 3 && !loading && (
					<>
						<Typography variant="subtitle1" gutterBottom>
							{t('pages.order_dialog.selection')}
						</Typography>
						<Typography variant="body1">
							<strong>Service:</strong> {toHumanReadable(formData.category)}
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>Order:</strong> {selectedItem}
						</Typography>
						<Typography variant="subtitle1" gutterBottom>
							{t('pages.order_dialog.fill_details')}
						</Typography>
						<Grid container spacing={2}>
							{selectedFields.map((field, index) => (
								<React.Fragment key={index}>
									<Grid item xs={12}>
										{field.type === 'text' && (
											<TextField
												fullWidth
												required
												label={t(`services.labels.${field.key}`)}
												placeholder={t(field.hint)}
												value={formData[field.key] || ''}
												onChange={(e) => handleFieldChange(field.key, e.target.value)}
											/>
										)}
										{field.type === 'switch' && (
											<FormControlLabel
												control={
													<Switch
														checked={formData[field.key] || false}
														onChange={(e) => handleFieldChange(field.key, e.target.checked)}
													/>
												}
												label={t(`services.labels.${field.key}`)}
												sx={{marginRight: '5px'}}
											/>
										)}
										{field.tooltip && (
											<Tooltip title={t(`services.tooltips.${field.key}`)}>
												<IconButton sx={{padding: 0}}>
													<Info sx={{width: '20px'}}/>
												</IconButton>
											</Tooltip>
										)}
									</Grid>
									{field.key === 'analog' && formData['analog'] && (
										<Grid item xs={12}>
											<TextField
												fullWidth
												required
												label={t(`services.labels.${field.key}`)}
												placeholder="Reference number"
												value={formData['reference_number'] || ''}
												onChange={(e) => handleFieldChange('reference_number', e.target.value)}
											/>
										</Grid>
									)}
								</React.Fragment>
							))}
							<Grid item xs={12}>
								<TextField
									fullWidth
									label={t(`pages.order_dialog.extra_info`)}
									placeholder="Additional information"
									value={formData['extra_info'] || ''}
									onChange={(e) => handleFieldChange('extra_info', e.target.value)}
								/>
							</Grid>
							<Grid item xs={12}>
								<div {...getRootProps({ style: { border: `2px dashed ${borderColor}`, padding: '20px', textAlign: 'center', cursor: 'pointer' } })}>
									<input {...getInputProps()} />
									<Typography>{t('pages.order_dialog.drag_drop')}</Typography>
									<Typography variant="caption">{t('pages.order_dialog.accept_formats')} STL, PLY, DCM, JPG, JPEG, GIF, PNG</Typography>
								</div>
								<div>
                                    {uploadedFiles.map((file, index) => (
                                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '0px' }}>
                                            <Typography style={{ flexGrow: 1 }}>{file.name}</Typography>
                                            <IconButton onClick={() => handleRemoveFile(file)} edge="end">
                                                <Delete />
                                            </IconButton>
                                        </div>
                                    ))}
                                </div>
							</Grid>
						</Grid>
					</>
				)}
				{step === 3 && loading && (
					<Box 
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						marginTop="50px"
					>
						<CircularProgress size={60}/>
						<Typography variant="subtitle1" gutterBottom mt="10px">
							{loadingText}
						</Typography>
					</Box>
				)}
				{step === 4 && (
					<>
						{/* <Typography variant="subtitle1" gutterBottom>
							{t('pages.order_dialog.confirmation')}
						</Typography> */}
						<Typography variant="body1" gutterBottom>
							{confirmationMessage}
						</Typography>
					</>
				)}
			</DialogContent>
			<DialogActions>
				{step > 1 && step < 4 && (
					<Button onClick={handleBack} disabled={loading}>
						{t('pages.order_dialog.buttons.back')}
					</Button>
				)}
				{step > 1 && step < 4 && (
					<Button onClick={() => closeDialog?.({}, "escapeKeyDown")} disabled={loading}>
						{t('pages.order_dialog.buttons.close')}
					</Button>
				)}
				{step === 2 && (
					<Button onClick={() => setStep(3)} variant="contained" disabled={!selectedItem}>
						{t('pages.order_dialog.buttons.next')}
					</Button>
				)}
				{step === 3 && (
					<Button onClick={handleSubmit} variant="contained" disabled={!isFormValid() || loading}>
						{loading ? <CircularProgress size={24} /> : (selectedRow ? 'Save' : t('pages.order_dialog.buttons.confirm'))}
					</Button>
				)}
				{step ===4 && (
					<Button onClick={() => closeDialog?.({}, "escapeKeyDown")} disabled={loading}>
						{t('pages.order_dialog.buttons.done')}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	)
}