import { Skeleton } from "@mui/material";

export function Loader(props: {
    isLoading: boolean;
    height?: number; 
    children: any; 
}) {
    if (!props.isLoading) {
        return props.children;
    }
    
    return (
        <Skeleton
            variant="rounded"
            width={'100%'}
            height={props.height} 
        />
    )
};
