import { useState, useEffect } from 'react'

const buildQueryString = (params: { [key: string]: string }) => {
    const queryItems = Object.keys(params)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))

    return queryItems.join('&')
}

export const useFetch = (
    url: string, 
    skip: boolean = false,
    params: {
        method: 'GET' | 'POST' | 'PUT' | 'DELETE',
        headers?: {
            [key: string]: string
        },
        queryParameters?: {
            [key: string]: string
        } | null,
        body?: any,
        useXHR?: boolean,
    } = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        queryParameters: null,
        body: null,
        useXHR: false,
    }, 
) => {
    const [data, setData] = useState(null);
    const [currentUrl, setCurrentUrl] = useState(url);
    const [isLoading, setIsLoading] = useState(!skip);
    const [currentSkip, setCurrentSkip] = useState(skip);
    const [errorMessage, setErrorMessage] = useState('');
    const [refetchIndex, setRefetchIndex] = useState(0);
    const [currentParams, setCurrentParams] = useState(params);
    
    const user = {
        username: 'admin',
        token: 'abc',
    }
    
    const refetch = () => {
        setCurrentSkip(false);
        setIsLoading(true);
        setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1)
    }

    const changeUrl = (newUrl: string) => {
        setCurrentUrl(newUrl)
    }

    const changeParams = (newParams: any) => {
        setCurrentParams(newParams)
    }

    useEffect(() => {
        const fetchData = async () => {
            if (currentSkip) return;

            setIsLoading(true);

            try {
                const queryParameters = currentParams.queryParameters
                    ? '?' + buildQueryString(currentParams.queryParameters) 
                    : ''

                if (currentParams.useXHR) {
                    const xhr = new XMLHttpRequest()
                    xhr.open(currentParams.method, `${currentUrl}${queryParameters}`)
                    xhr.setRequestHeader('Authorization', `Bearer ${user?.token}`)
                    xhr.send(currentParams.body)
                    xhr.onload = () => {
                        setIsLoading(false);
                        if (xhr.status === 200 || xhr.status === 201) {
                            setData(JSON.parse(xhr.response))
                        } else {
                            setErrorMessage(xhr.response)
                        }
                    }
                } else {
                    const response = await fetch(
                        `${currentUrl}${queryParameters}`,
                        {
                            method: currentParams.method,
                            headers: {
                                ...currentParams.headers,
                                // Authorization: `Bearer ${user?.token}`
                            },
                            ...(currentParams.body && { body: JSON.stringify(currentParams.body) }) 
                        }
                    )
                    const result = await response.json()
                    if (response.ok) {
                        setData(result)
                    } else {
                        setErrorMessage(result)
                    }
                }
            } catch (err: any) {
                setErrorMessage(err.message)
            } finally {
                if (!currentParams.useXHR) {
                    setIsLoading(false)
                }
            }
        }
        fetchData()
    }, [refetchIndex]);

    return { data, isLoading, errorMessage, refetch, changeUrl, changeParams }
}
