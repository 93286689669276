import * as React from 'react';
import { CRUDTable } from '../components/CRUDTable';
import DashboardLayout from '../layouts/DashboardLayout';
import useAuth, { User } from '../hooks/useAuth';

export default function Dashboard() {
    const { user } = useAuth();

    return (
       <DashboardLayout>
          <CRUDTable
              baseUrl={`https://backend.identaldesigner.nl/wp-json/wp/v2/custom_order?author=${user?.databaseId}&&per_page=100`}
              columns={[
                    {
                        name: 'Submitted',
                        key: 'date',
                    },
                    {
                        name: 'Description',
                        key: 'order_type',
                    },
                    {
                        name: 'Status',
                        key: 'status',
                    }
              ]}
              title="My Orders"
          />
      </DashboardLayout>
    );
}
