import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';

import { useMutation, gql } from "@apollo/client";

import { GET_USER } from "../../hooks/useAuth";
import { Alert, AlertColor } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SEND_PASSWORD_RECOVERY = gql`
  mutation($username: String!) {
    sendPasswordResetEmail(input: {
      username: $username
    }) {
      user {
        id
        email
      }
    }
  }
`;

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://identaldesigner.nl/">
        iDental Desinger Portal
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Forgot() {
  const [reset, { loading }] = useMutation(SEND_PASSWORD_RECOVERY);
  const [alertState, setAlertState] = React.useState({ open: false, severity: '', message: '' });
  const { t } = useTranslation();

  const errorExplanations: { [key: string]: string } = {
    empty_email: t('errors.enter_email'),
    empty_username: t('errors.enter_email'),
    invalid_email: t('errors.enter_email'),
    invalid_username: t('errors.enter_email'),
    empty_password: t('errors.enter_password'),
    incorrect_password: t('errors.incorrect_password'),
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);;

    if (data.get('email') === ''){
      setAlertState({ open: true, severity: 'error', message: t('errors.enter_email') });
      return;
    }

    reset({
      variables: {
        username: data.get('email'),
      }
    })
    .then((data) => {
      if (data?.extensions?.debug[0]) {
        setAlertState({ open: true, severity: 'error', message: data.extensions.debug[0].message });
        return;
      }
      setAlertState({ open: true, severity: 'success', message: 'A link has been sent to your email. Use it in order to reset your password.' });
    })
    .catch(error => {
      console.error(error.message);
      if (errorExplanations.hasOwnProperty(error.message)) {
        setAlertState({ open: true, severity: 'error', message: errorExplanations[error.message] });
      } else {
        setAlertState({ open: true, severity: 'error', message: t('errors.unknown') });
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Change your password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {loading && <LinearProgress />}
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Send
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />

      {alertState.open && (
        <Alert severity={alertState.severity as AlertColor} onClose={() => setAlertState({ ...alertState, open: false })}>
          {alertState.message}
        </Alert>
      )}
    </Container>
  );
}