import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';

import { useMutation, gql } from "@apollo/client";

import { GET_USER } from "../../hooks/useAuth";
import { Alert, AlertColor, IconButton, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MUIWrapperContext } from '../../components/MUIWrapper';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const LOG_IN = gql`
  mutation logIn($login: String!, $password: String!) {
    loginWithCookies(input: {
      login: $login
      password: $password
    }) {
      status
    }
  }
`;

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://identaldesigner.nl/">
        iDental Desinger Portal
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Login() {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const { toggleLanguage, countryFlag} = React.useContext(MUIWrapperContext);

  const [logIn, { loading }] = useMutation(LOG_IN, {
    refetchQueries: [
      { query: GET_USER }
    ],
  });
  const [alertState, setAlertState] = React.useState({ open: false, severity: '', message: '' });

  const errorExplanations: { [key: string]: string } = {
    empty_email: t('pages.sign_in.errors.invalid_email'),
    empty_username: t('pages.sign_in.errors.invalid_email'),
    invalid_email: t('pages.sign_in.errors.invalid_email'),
    invalid_username: t('pages.sign_in.errors.invalid_email'),
    empty_password: t('pages.sign_in.errors.invalid_password'),
    incorrect_password: t('pages.sign_in.errors.invalid_password'),
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);;

    if (data.get('email') === '' || data.get('password') === ''){
      setAlertState({ open: true, severity: 'error', message: 'Please enter your credentials.' });
      return;
    }

    logIn({
      variables: {
        login: data.get('email'),
        password: data.get('password'),
      }
    })
    .then(() => {
      setAlertState({ open: true, severity: 'success', message: t('errors.success_login') });
      // Redirect to the dashboard
      window.location.href = '/dashboard';
    })
    .catch(error => {
      console.error(error);
      if (errorExplanations.hasOwnProperty(error.message)) {
        setAlertState({ open: true, severity: 'error', message: errorExplanations[error.message] });
      } else {
        setAlertState({ open: true, severity: 'error', message: t('errors.unknown') });
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box 
          component="img"
          src="/logo_icon.png"
          alt="logo"
          sx={{
            width: {
              xs: '100px',
              md: '150px'
            }
          }}
        />
        <Typography component="h1" variant="h5" style={{ color: '#4581ff' }}>
          <span style={{ color: '#4581ff' }}>i</span>
          <span style={{ color: '#7a7a7a' }}>Dental Designer</span> Portal
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {loading && <LinearProgress />}
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('user_info.email')}
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('user_info.password')}
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            InputProps={{ // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label={t('pages.sign_in.remember_me')}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t('pages.sign_in.sign_in')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/change-password" variant="body2">
                {t('pages.sign_in.forgot_password')}
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                {t('pages.sign_in.no_account')}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box 
        sx={{
          display: 'flex', 
          flexGrow: 0, 
          justifyContent: 'center', 
          alignItems: 'center',
          mt: 8,
        }}
      >
        {t('language')}:
        <IconButton 
          onClick={() => {
            toggleLanguage();
          }} 
        >
          <img 
            src={countryFlag} 
            alt="country-flag"
            style={{width: '30px', height: '20px', borderRadius: '5px'}}
          />
        </IconButton>
      </Box>

      <Copyright sx={{  mb: 4 }} />

      {alertState.open && (
        <Alert severity={alertState.severity as AlertColor} onClose={() => setAlertState({ ...alertState, open: false })}>
          {alertState.message}
        </Alert>
      )}
    </Container>
  );
}