// @flow

type Country = {
    code: string,
    phone: string,
    icon: string,
    label: string,
    suggested?: boolean,
};
  
export const allCountries: Array<Country> = [
    { icon: 'đŚđŤ', label: 'Afghanistan', code: 'AF', phone: '93' },
    { icon: 'đŚđ˝', label: 'Alland Islands', code: 'AX', phone: '358' },
    { icon: 'đŚđą', label: 'Albania', code: 'AL', phone: '355' },
    { icon: 'đŠđż', label: 'Algeria', code: 'DZ', phone: '213' },
    { icon: 'đŚđ¸', label: 'American Samoa', code: 'AS', phone: '1-684' },
    { icon: 'đŚđŠ', label: 'Andorra', code: 'AD', phone: '376' },
    { icon: 'đŚđ´', label: 'Angola', code: 'AO', phone: '244' },
    { icon: 'đŚđŽ', label: 'Anguilla', code: 'AI', phone: '1-264' },
    { icon: 'đŚđś', label: 'Antarctica', code: 'AQ', phone: '672' },
    { icon: 'đŚđŹ', label: 'Antigua and Barbuda', code: 'AG', phone: '1-268' },
    { icon: 'đŚđˇ', label: 'Argentina', code: 'AR', phone: '54' },
    { icon: 'đŚđ˛', label: 'Armenia', code: 'AM', phone: '374' },
    { icon: 'đŚđź', label: 'Aruba', code: 'AW', phone: '297' },
    { icon: 'đŚđş', label: 'Australia', code: 'AU', phone: '61', suggested: true },
    { icon: 'đŚđš', label: 'Austria', code: 'AT', phone: '43' },
    { icon: 'đŚđż', label: 'Azerbaijan', code: 'AZ', phone: '994' },
    { icon: 'đ§đ¸', label: 'Bahamas', code: 'BS', phone: '1-242' },
    { icon: 'đ§đ­', label: 'Bahrain', code: 'BH', phone: '973' },
    { icon: 'đ§đŠ', label: 'Bangladesh', code: 'BD', phone: '880' },
    { icon: 'đ§đ§', label: 'Barbados', code: 'BB', phone: '1-246' },
    { icon: 'đ§đž', label: 'Belarus', code: 'BY', phone: '375' },
    { icon: 'đ§đŞ', label: 'Belgium', code: 'BE', phone: '32' },
    { icon: 'đ§đż', label: 'Belize', code: 'BZ', phone: '501' },
    { icon: 'đ§đŻ', label: 'Benin', code: 'BJ', phone: '229' },
    { icon: 'đ§đ˛', label: 'Bermuda', code: 'BM', phone: '1-441' },
    { icon: 'đ§đš', label: 'Bhutan', code: 'BT', phone: '975' },
    { icon: 'đ§đ´', label: 'Bolivia', code: 'BO', phone: '591' },
    { icon: 'đ§đŚ', label: 'Bosnia and Herzegovina', code: 'BA', phone: '387' },
    { icon: 'đ§đź', label: 'Botswana', code: 'BW', phone: '267' },
    { icon: 'đ§đť', label: 'Bouvet Island', code: 'BV', phone: '47' },
    { icon: 'đ§đˇ', label: 'Brazil', code: 'BR', phone: '55' },
    {
      icon: 'đŽđ´',
      label: 'British Indian Ocean Territory',
      code: 'IO',
      phone: '246',
    },
    { icon: 'đťđŹ', label: 'British Virgin Islands', code: 'VG', phone: '1-284' },
    { icon: 'đ§đł', label: 'Brunei Darussalam', code: 'BN', phone: '673' },
    { icon: 'đ§đŹ', label: 'Bulgaria', code: 'BG', phone: '359' },
    { icon: 'đ§đŤ', label: 'Burkina Faso', code: 'BF', phone: '226' },
    { icon: 'đ§đŽ', label: 'Burundi', code: 'BI', phone: '257' },
    { icon: 'đ°đ­', label: 'Cambodia', code: 'KH', phone: '855' },
    { icon: 'đ¨đ˛', label: 'Cameroon', code: 'CM', phone: '237' },
    { icon: 'đ¨đŚ', label: 'Canada', code: 'CA', phone: '1', suggested: true },
    { icon: 'đ¨đť', label: 'Cape Verde', code: 'CV', phone: '238' },
    { icon: 'đ°đž', label: 'Cayman Islands', code: 'KY', phone: '1-345' },
    { icon: 'đ¨đŤ', label: 'Central African Republic', code: 'CF', phone: '236' },
    { icon: 'đšđŠ', label: 'Chad', code: 'TD', phone: '235' },
    { icon: 'đ¨đą', label: 'Chile', code: 'CL', phone: '56' },
    { icon: 'đ¨đł', label: 'China', code: 'CN', phone: '86' },
    { icon: 'đ¨đ˝', label: 'Christmas Island', code: 'CX', phone: '61' },
    { icon: 'đ¨đ¨', label: 'Cocos (Keeling) Islands', code: 'CC', phone: '61' },
    { icon: 'đ¨đ´', label: 'Colombia', code: 'CO', phone: '57' },
    { icon: 'đ°đ˛', label: 'Comoros', code: 'KM', phone: '269' },
    {
      icon: 'đ¨đŠ',
      label: 'Congo, Democratic Republic of the',
      code: 'CG',
      phone: '243',
    },
    {
      icon: 'đ¨đŹ',
      label: 'Congo, Republic of the',
      code: 'CD',
      phone: '242',
    },
    { icon: 'đ¨đ°', label: 'Cook Islands', code: 'CK', phone: '682' },
    { icon: 'đ¨đˇ', label: 'Costa Rica', code: 'CR', phone: '506' },
    { icon: 'đ¨đŽ', label: "Cote d'Ivoire", code: 'CI', phone: '225' },
    { icon: 'đ­đˇ', label: 'Croatia', code: 'HR', phone: '385' },
    { icon: 'đ¨đş', label: 'Cuba', code: 'CU', phone: '53' },
    { icon: 'đ¨đź', label: 'Curacao', code: 'CW', phone: '599' },
    { icon: 'đ¨đž', label: 'Cyprus', code: 'CY', phone: '357' },
    { icon: 'đ¨đż', label: 'Czech Republic', code: 'CZ', phone: '420' },
    { icon: 'đŠđ°', label: 'Denmark', code: 'DK', phone: '45' },
    { icon: 'đŠđŻ', label: 'Djibouti', code: 'DJ', phone: '253' },
    { icon: 'đŠđ˛', label: 'Dominica', code: 'DM', phone: '1-767' },
    { icon: 'đŠđ´', label: 'Dominican Republic', code: 'DO', phone: '1-809' },
    { icon: 'đŞđ¨', label: 'Ecuador', code: 'EC', phone: '593' },
    { icon: 'đŞđŹ', label: 'Egypt', code: 'EG', phone: '20' },
    { icon: 'đ¸đť', label: 'El Salvador', code: 'SV', phone: '503' },
    { icon: 'đŹđś', label: 'Equatorial Guinea', code: 'GQ', phone: '240' },
    { icon: 'đŞđˇ', label: 'Eritrea', code: 'ER', phone: '291' },
    { icon: 'đŞđŞ', label: 'Estonia', code: 'EE', phone: '372' },
    { icon: 'đŞđš', label: 'Ethiopia', code: 'ET', phone: '251' },
    { icon: 'đŤđ°', label: 'Falkland Islands (Malvinas)', code: 'FK', phone: '500' },
    { icon: 'đŤđ´', label: 'Faroe Islands', code: 'FO', phone: '298' },
    { icon: 'đŤđŻ', label: 'Fiji', code: 'FJ', phone: '679' },
    { icon: 'đŤđŽ', label: 'Finland', code: 'FI', phone: '358' },
    { icon: 'đŤđˇ', label: 'France', code: 'FR', phone: '33', suggested: true },
    { icon: 'đŹđŤ', label: 'French Guiana', code: 'GF', phone: '594' },
    { icon: 'đľđŤ', label: 'French Polynesia', code: 'PF', phone: '689' },
    { icon: 'đšđŤ', label: 'French Southern Territories', code: 'TF', phone: '262' },
    { icon: 'đŹđŚ', label: 'Gabon', code: 'GA', phone: '241' },
    { icon: 'đŹđ˛', label: 'Gambia', code: 'GM', phone: '220' },
    { icon: 'đŹđŞ', label: 'Georgia', code: 'GE', phone: '995' },
    { icon: 'đŠđŞ', label: 'Germany', code: 'DE', phone: '49', suggested: true },
    { icon: 'đŹđ­', label: 'Ghana', code: 'GH', phone: '233' },
    { icon: 'đŹđŽ', label: 'Gibraltar', code: 'GI', phone: '350' },
    { icon: 'đŹđˇ', label: 'Greece', code: 'GR', phone: '30' },
    { icon: 'đŹđą', label: 'Greenland', code: 'GL', phone: '299' },
    { icon: 'đŹđŠ', label: 'Grenada', code: 'GD', phone: '1-473' },
    { icon: 'đŹđľ', label: 'Guadeloupe', code: 'GP', phone: '590' },
    { icon: 'đŹđş', label: 'Guam', code: 'GU', phone: '1-671' },
    { icon: 'đŹđš', label: 'Guatemala', code: 'GT', phone: '502' },
    { icon: 'đŹđŹ', label: 'Guernsey', code: 'GG', phone: '44' },
    { icon: 'đŹđź', label: 'Guinea-Bissau', code: 'GW', phone: '245' },
    { icon: 'đŹđł', label: 'Guinea', code: 'GN', phone: '224' },
    { icon: 'đŹđž', label: 'Guyana', code: 'GY', phone: '592' },
    { icon: 'đ­đš', label: 'Haiti', code: 'HT', phone: '509' },
    {
      icon: 'đ­đ˛',
      label: 'Heard Island and McDonald Islands',
      code: 'HM',
      phone: '672',
    },
    {
      icon: 'đťđŚ',
      label: 'Holy See (Vatican City State)',
      code: 'VA',
      phone: '379',
    },
    { icon: 'đ­đł', label: 'Honduras', code: 'HN', phone: '504' },
    { icon: 'đ­đ°', label: 'Hong Kong', code: 'HK', phone: '852' },
    { icon: 'đ­đş', label: 'Hungary', code: 'HU', phone: '36' },
    { icon: 'đŽđ¸', label: 'Iceland', code: 'IS', phone: '354' },
    { icon: 'đŽđł', label: 'India', code: 'IN', phone: '91' },
    { icon: 'đŽđŠ', label: 'Indonesia', code: 'ID', phone: '62' },
    { icon: 'đŽđˇ', label: 'Iran, Islamic Republic of', code: 'IR', phone: '98' },
    { icon: 'đŽđś', label: 'Iraq', code: 'IQ', phone: '964' },
    { icon: 'đŽđŞ', label: 'Ireland', code: 'IE', phone: '353' },
    { icon: 'đŽđ˛', label: 'Isle of Man', code: 'IM', phone: '44' },
    { icon: 'đŽđą', label: 'Israel', code: 'IL', phone: '972' },
    { icon: 'đŽđš', label: 'Italy', code: 'IT', phone: '39' },
    { icon: 'đŻđ˛', label: 'Jamaica', code: 'JM', phone: '1-876' },
    { icon: 'đŻđľ', label: 'Japan', code: 'JP', phone: '81', suggested: true },
    { icon: 'đŻđŞ', label: 'Jersey', code: 'JE', phone: '44' },
    { icon: 'đŻđ´', label: 'Jordan', code: 'JO', phone: '962' },
    { icon: 'đ°đż', label: 'Kazakhstan', code: 'KZ', phone: '7' },
    { icon: 'đ°đŞ', label: 'Kenya', code: 'KE', phone: '254' },
    { icon: 'đ°đŽ', label: 'Kiribati', code: 'KI', phone: '686' },
    {
      icon: 'đ°đľ',
      label: "Korea, Democratic People's Republic of",
      code: 'KP',
      phone: '850',
    },
    { icon: 'đ°đˇ', label: 'Korea, Republic of', code: 'KR', phone: '82' },
    { icon: 'đ˝đ°', label: 'Kosovo', code: 'XK', phone: '383' },
    { icon: 'đ°đź', label: 'Kuwait', code: 'KW', phone: '965' },
    { icon: 'đ°đŹ', label: 'Kyrgyzstan', code: 'KG', phone: '996' },
    {
      icon: 'đąđŚ',
      label: "Lao People's Democratic Republic",
      code: 'LA',
      phone: '856',
    },
    { icon: 'đąđť', label: 'Latvia', code: 'LV', phone: '371' },
    { icon: 'đąđ§', label: 'Lebanon', code: 'LB', phone: '961' },
    { icon: 'đąđ¸', label: 'Lesotho', code: 'LS', phone: '266' },
    { icon: 'đąđˇ', label: 'Liberia', code: 'LR', phone: '231' },
    { icon: 'đąđž', label: 'Libya', code: 'LY', phone: '218' },
    { icon: 'đąđŽ', label: 'Liechtenstein', code: 'LI', phone: '423' },
    { icon: 'đąđš', label: 'Lithuania', code: 'LT', phone: '370' },
    { icon: 'đąđş', label: 'Luxembourg', code: 'LU', phone: '352' },
    { icon: 'đ˛đ´', label: 'Macao', code: 'MO', phone: '853' },
    {
      icon: 'đ˛đ°',
      label: 'Macedonia, the Former Yugoslav Republic of',
      code: 'MK',
      phone: '389',
    },
    { icon: 'đ˛đŹ', label: 'Madagascar', code: 'MG', phone: '261' },
    { icon: 'đ˛đź', label: 'Malawi', code: 'MW', phone: '265' },
    { icon: 'đ˛đž', label: 'Malaysia', code: 'MY', phone: '60' },
    { icon: 'đ˛đť', label: 'Maldives', code: 'MV', phone: '960' },
    { icon: 'đ˛đą', label: 'Mali', code: 'ML', phone: '223' },
    { icon: 'đ˛đš', label: 'Malta', code: 'MT', phone: '356' },
    { icon: 'đ˛đ­', label: 'Marshall Islands', code: 'MH', phone: '692' },
    { icon: 'đ˛đś', label: 'Martinique', code: 'MQ', phone: '596' },
    { icon: 'đ˛đˇ', label: 'Mauritania', code: 'MR', phone: '222' },
    { icon: 'đ˛đş', label: 'Mauritius', code: 'MU', phone: '230' },
    { icon: 'đžđš', label: 'Mayotte', code: 'YT', phone: '262' },
    { icon: 'đ˛đ˝', label: 'Mexico', code: 'MX', phone: '52' },
    {
      icon: 'đŤđ˛',
      label: 'Micronesia, Federated States of',
      code: 'FM',
      phone: '691',
    },
    { icon: 'đ˛đŠ', label: 'Moldova, Republic of', code: 'MD', phone: '373' },
    { icon: 'đ˛đ¨', label: 'Monaco', code: 'MC', phone: '377' },
    { icon: 'đ˛đł', label: 'Mongolia', code: 'MN', phone: '976' },
    { icon: 'đ˛đŞ', label: 'Montenegro', code: 'ME', phone: '382' },
    { icon: 'đ˛đ¸', label: 'Montserrat', code: 'MS', phone: '1-664' },
    { icon: 'đ˛đŚ', label: 'Morocco', code: 'MA', phone: '212' },
    { icon: 'đ˛đż', label: 'Mozambique', code: 'MZ', phone: '258' },
    { icon: 'đ˛đ˛', label: 'Myanmar', code: 'MM', phone: '95' },
    { icon: 'đłđŚ', label: 'Namibia', code: 'NA', phone: '264' },
    { icon: 'đłđˇ', label: 'Nauru', code: 'NR', phone: '674' },
    { icon: 'đłđľ', label: 'Nepal', code: 'NP', phone: '977' },
    { icon: 'đłđą', label: 'Netherlands', code: 'NL', phone: '31' },
    { icon: 'đłđ¨', label: 'New Caledonia', code: 'NC', phone: '687' },
    { icon: 'đłđż', label: 'New Zealand', code: 'NZ', phone: '64' },
    { icon: 'đłđŽ', label: 'Nicaragua', code: 'NI', phone: '505' },
    { icon: 'đłđŞ', label: 'Niger', code: 'NE', phone: '227' },
    { icon: 'đłđŹ', label: 'Nigeria', code: 'NG', phone: '234' },
    { icon: 'đłđş', label: 'Niue', code: 'NU', phone: '683' },
    { icon: 'đłđŤ', label: 'Norfolk Island', code: 'NF', phone: '672' },
    { icon: 'đ˛đľ', label: 'Northern Mariana Islands', code: 'MP', phone: '1-670' },
    { icon: 'đłđ´', label: 'Norway', code: 'NO', phone: '47' },
    { icon: 'đ´đ˛', label: 'Oman', code: 'OM', phone: '968' },
    { icon: 'đľđ°', label: 'Pakistan', code: 'PK', phone: '92' },
    { icon: 'đľđź', label: 'Palau', code: 'PW', phone: '680' },
    { icon: 'đľđ¸', label: 'Palestine, State of', code: 'PS', phone: '970' },
    { icon: 'đľđŚ', label: 'Panama', code: 'PA', phone: '507' },
    { icon: 'đľđŹ', label: 'Papua New Guinea', code: 'PG', phone: '675' },
    { icon: 'đľđž', label: 'Paraguay', code: 'PY', phone: '595' },
    { icon: 'đľđŞ', label: 'Peru', code: 'PE', phone: '51' },
    { icon: 'đľđ­', label: 'Philippines', code: 'PH', phone: '63' },
    { icon: 'đľđł', label: 'Pitcairn', code: 'PN', phone: '870' },
    { icon: 'đľđą', label: 'Poland', code: 'PL', phone: '48' },
    { icon: 'đľđš', label: 'Portugal', code: 'PT', phone: '351' },
    { icon: 'đľđˇ', label: 'Puerto Rico', code: 'PR', phone: '1' },
    { icon: 'đśđŚ', label: 'Qatar', code: 'QA', phone: '974' },
    { icon: 'đˇđŞ', label: 'Reunion', code: 'RE', phone: '262' },
    { icon: 'đˇđ´', label: 'Romania', code: 'RO', phone: '40' },
    { icon: 'đˇđş', label: 'Russian Federation', code: 'RU', phone: '7' },
    { icon: 'đˇđź', label: 'Rwanda', code: 'RW', phone: '250' },
    { icon: 'đ§đą', label: 'Saint Barthelemy', code: 'BL', phone: '590' },
    { icon: 'đ¸đ­', label: 'Saint Helena', code: 'SH', phone: '290' },
    { icon: 'đ°đł', label: 'Saint Kitts and Nevis', code: 'KN', phone: '1-869' },
    { icon: 'đąđ¨', label: 'Saint Lucia', code: 'LC', phone: '1-758' },
    { icon: 'đ˛đŤ', label: 'Saint Martin (French part)', code: 'MF', phone: '590' },
    { icon: 'đľđ˛', label: 'Saint Pierre and Miquelon', code: 'PM', phone: '508' },
    {
      icon: 'đťđ¨',
      label: 'Saint Vincent and the Grenadines',
      code: 'VC',
      phone: '1-784',
    },
    { icon: 'đźđ¸', label: 'Samoa', code: 'WS', phone: '685' },
    { icon: 'đ¸đ˛', label: 'San Marino', code: 'SM', phone: '378' },
    { icon: 'đ¸đš', label: 'Sao Tome and Principe', code: 'ST', phone: '239' },
    { icon: 'đ¸đŚ', label: 'Saudi Arabia', code: 'SA', phone: '966' },
    { icon: 'đ¸đł', label: 'Senegal', code: 'SN', phone: '221' },
    { icon: 'đˇđ¸', label: 'Serbia', code: 'RS', phone: '381' },
    { icon: 'đ¸đ¨', label: 'Seychelles', code: 'SC', phone: '248' },
    { icon: 'đ¸đą', label: 'Sierra Leone', code: 'SL', phone: '232' },
    { icon: 'đ¸đŹ', label: 'Singapore', code: 'SG', phone: '65' },
    { icon: 'đ¸đ˝', label: 'Sint Maarten (Dutch part)', code: 'SX', phone: '1-721' },
    { icon: 'đ¸đ°', label: 'Slovakia', code: 'SK', phone: '421' },
    { icon: 'đ¸đŽ', label: 'Slovenia', code: 'SI', phone: '386' },
    { icon: 'đ¸đ§', label: 'Solomon Islands', code: 'SB', phone: '677' },
    { icon: 'đ¸đ´', label: 'Somalia', code: 'SO', phone: '252' },
    { icon: 'đżđŚ', label: 'South Africa', code: 'ZA', phone: '27' },
    {
      icon: 'đŹđ¸',
      label: 'South Georgia and the South Sandwich Islands',
      code: 'GS',
      phone: '500',
    },
    { icon: 'đ¸đ¸', label: 'South Sudan', code: 'SS', phone: '211' },
    { icon: 'đŞđ¸', label: 'Spain', code: 'ES', phone: '34' },
    { icon: 'đąđ°', label: 'Sri Lanka', code: 'LK', phone: '94' },
    { icon: 'đ¸đŠ', label: 'Sudan', code: 'SD', phone: '249' },
    { icon: 'đ¸đˇ', label: 'Surilabel', code: 'SR', phone: '597' },
    { icon: 'đ¸đŻ', label: 'Svalbard and Jan Mayen', code: 'SJ', phone: '47' },
    { icon: 'đ¸đż', label: 'Swaziland', code: 'SZ', phone: '268' },
    { icon: 'đ¸đŞ', label: 'Sweden', code: 'SE', phone: '46' },
    { icon: 'đ¨đ­', label: 'Switzerland', code: 'CH', phone: '41' },
    { icon: 'đ¸đž', label: 'Syrian Arab Republic', code: 'SY', phone: '963' },
    { icon: 'đšđź', label: 'Taiwan, Province of China', code: 'TW', phone: '886' },
    { icon: 'đšđŻ', label: 'Tajikistan', code: 'TJ', phone: '992' },
    { icon: 'đšđ­', label: 'Thailand', code: 'TH', phone: '66' },
    { icon: 'đšđą', label: 'Timor-Leste', code: 'TL', phone: '670' },
    { icon: 'đšđŹ', label: 'Togo', code: 'TG', phone: '228' },
    { icon: 'đšđ°', label: 'Tokelau', code: 'TK', phone: '690' },
    { icon: 'đšđ´', label: 'Tonga', code: 'TO', phone: '676' },
    { icon: 'đšđš', label: 'Trinidad and Tobago', code: 'TT', phone: '1-868' },
    { icon: 'đšđł', label: 'Tunisia', code: 'TN', phone: '216' },
    { icon: 'đšđˇ', label: 'Turkey', code: 'TR', phone: '90' },
    { icon: 'đšđ˛', label: 'Turkmenistan', code: 'TM', phone: '993' },
    { icon: 'đšđ¨', label: 'Turks and Caicos Islands', code: 'TC', phone: '1-649' },
    { icon: 'đšđť', label: 'Tuvalu', code: 'TV', phone: '688' },
    { icon: 'đşđŹ', label: 'Uganda', code: 'UG', phone: '256' },
    { icon: 'đşđŚ', label: 'Ukraine', code: 'UA', phone: '380' },
    { icon: 'đŚđŞ', label: 'United Arab Emirates', code: 'AE', phone: '971' },
    { icon: 'đŹđ§', label: 'United Kingdom', code: 'GB', phone: '44' },
    { icon: 'đšđż', label: 'United Republic of Tanzania', code: 'TZ', phone: '255' },
    { icon: 'đşđ˛', label: 'United States', code: 'US', phone: '1', suggested: true },
    { icon: 'đşđž', label: 'Uruguay', code: 'UY', phone: '598' },
    { icon: 'đťđŽ', label: 'US Virgin Islands', code: 'VI', phone: '1-340' },
    { icon: 'đşđż', label: 'Uzbekistan', code: 'UZ', phone: '998' },
    { icon: 'đťđş', label: 'Vanuatu', code: 'VU', phone: '678' },
    { icon: 'đťđŞ', label: 'Venezuela', code: 'VE', phone: '58' },
    { icon: 'đťđł', label: 'Vietnam', code: 'VN', phone: '84' },
    { icon: 'đźđŤ', label: 'Wallis and Futuna', code: 'WF', phone: '681' },
    { icon: 'đŞđ­', label: 'Western Sahara', code: 'EH', phone: '212' },
    { icon: 'đžđŞ', label: 'Yemen', code: 'YE', phone: '967' },
    { icon: 'đżđ˛', label: 'Zambia', code: 'ZM', phone: '260' },
    { icon: 'đżđź', label: 'Zimbabwe', code: 'ZW', phone: '263' },
  ];
  
// separate countries into groups
export const groupedCountries: Array<{
    label: string,
    options: Array<Country>,
  }> = [
    {
      label: 'Suggested',
      options: allCountries.filter(c => c.suggested),
    },
    {
      label: 'All Countries',
      options: allCountries.filter(c => !c.suggested),
    },
];