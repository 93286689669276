import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';

import { useMutation, gql } from "@apollo/client";

import { GET_USER } from "../../hooks/useAuth";
import { Alert, AlertColor } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const RESET_USER_PASSWORD = gql`
    mutation RESET_USER_PASSWORD($key: String!, $login: String!, $password: String!) {
        resetUserPassword(input: {
            key: $key,
            login: $login,
            password: $password
        }) {
            user {
            id
            }
        }
    }
`;

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://identaldesigner.nl/">
        iDental Desinger Portal
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function ResetPassword() {
  const [resetPassword, { loading }] = useMutation(RESET_USER_PASSWORD, {
    refetchQueries: [
      { query: GET_USER }
    ],
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [alertState, setAlertState] = React.useState({ open: false, severity: '', message: '' });
  const { t } = useTranslation();

  const errorExplanations: { [key: string]: string } = {
    'Password reset link is invalid.': 'Password reset link is invalid.',
    empty_password: t('errors.enter_password'),
    incorrect_password: t('errors.incorrect_password'),
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);;

    if (data.get('password') === ''){
      setAlertState({ open: true, severity: 'error', message: 'Please enter your new password' });
      return;
    }

    if (data.get('password') !== data.get('password2')){
        setAlertState({ open: true, severity: 'error', message: t('errors.password_match') });
        return;
    }

    resetPassword({
      variables: {
        key: searchParams.get("key"),
        login: searchParams.get('login'),
        password: data.get('password'),
      }
    })
    .then((data) => {
      setAlertState({ open: true, severity: 'success', message: 'You have successfully reset your password. Redirecting you to the login page.' });

        setTimeout(() => {
            window.location.href = '/';
        }, 3000);
    })
    .catch(error => {
      console.error(error.message);
      if (errorExplanations.hasOwnProperty(error.message)) {
        setAlertState({ open: true, severity: 'error', message: errorExplanations[error.message] });
      } else {
        setAlertState({ open: true, severity: 'error', message: t('errors.unknown') });
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5" style={{ color: '#4581ff' }}>
          <span style={{ color: '#4581ff' }}>i</span>
          <span style={{ color: '#7a7a7a' }}>Dental Designer</span> Portal
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {loading && <LinearProgress />}
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password2"
            label="Repeat password"
            type="password"
            id="password2"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />

      {alertState.open && (
        <Alert severity={alertState.severity as AlertColor} onClose={() => setAlertState({ ...alertState, open: false })}>
          {alertState.message}
        </Alert>
      )}
    </Container>
  );
}