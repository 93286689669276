import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';

import { City, ICity }  from 'country-state-city';
import { allCountries } from '../../utils/countries';

import { useMutation, gql } from "@apollo/client";

import { GET_USER } from "../../hooks/useAuth";
import { Alert, AlertColor, Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';

const REGISTER_USER = gql`
  mutation registerUser(
    $email: String!
    $firstName: String!
    $lastName: String!
  ) {
    registerUser(
      input: {
        username: $email
        email: $email
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      user {
        databaseId
      }
    }
  }
`;

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://identaldesigner.nl/">
        iDental Desinger Portal
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Signup() {
  const [register, { data, loading, error }] = useMutation(REGISTER_USER);

  const [alertState, setAlertState] = React.useState({ open: false, severity: '', message: '' });
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const [country, setCountry] = React.useState({
    "icon": "đłđą",
    "label": "Netherlands",
    "code": "NL",
    "phone": "31"
  });
  const [city, setCity] = React.useState(null);
  const [phoneCode, setPhoneCode] = React.useState({
    "icon": "đłđą",
    "label": "Netherlands",
    "code": "NL",
    "phone": "31"
  });
  const [availableCities, setAvailableCities] = React.useState<ICity[] | undefined | null>(City.getCitiesOfCountry("NL"));
  const [loader, setLoader] = React.useState(false);
  const [myFormRef, setMyFormRef ] = React.useState(null);
  const { t } = useTranslation();

  const errorExplanations: { [key: string]: string } = {
    // TODO add more error explanations
    '&lt;strong&gt;Error:&lt;/strong&gt; This email address is already registered. &lt;a href=&quot;https://backend.identaldesigner.nl/wp-login.php&quot;&gt;Log in&lt;/a&gt; with this address or choose another one.': t('errors.email_registered'),
    '&lt;strong&gt;Error:&lt;/strong&gt; This username is already registered. Please choose another one.': t('errors.email_registered'),
    'Username or Email already exists.': t('errors.email_registered'),
    'The email address you are trying to use is invalid': t('errors.email_invalid'),
  };

  const handleCountryChange = (event: any, value: any) => {
    setCountry(value);
    setPhoneCode(value); // Set phone code
    setCity(null); // Reset city
    if (value) {
      setAvailableCities(City.getCitiesOfCountry(value.code));
    } else {
      setAvailableCities([]);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const allFields = [
      'firstName', 'lastName', 'company', 'zip', 'street', 
      'street_no', 'phone', 'email', 'email2', 'password', 'password2'
    ]

    if (!termsAccepted) {
      setAlertState({ open: true, severity: 'error', message: t('errors.terms') });
      return;
    }

    // Check if data is missing
    for (const field of allFields) {
      if (!formData.get(field)) {
        setAlertState({ open: true, severity: 'error', message: t('errors.select_fields') });
        return;
      }
    }

    if (!country || !city) {
      setAlertState({ open: true, severity: 'error', message: t('errors.select_country') });
      return;
    }
    
    // Check if email and confirm email are the same
    if (formData.get('email') !== formData.get('email2')) {
      setAlertState({ open: true, severity: 'error', message: t('errors.email_match') });
      return;
    }

    // Check if password and confirm password are the same
    if (formData.get('password') !== formData.get('password2')) {
      setAlertState({ open: true, severity: 'error', message: t('errors.password_match') });
      return;
    }

    setAlertState({ ...alertState, open: false })
    
    setLoader(true);

    fetch('https://backend.identaldesigner.nl/wp-json/custom/v1/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: formData.get('email'),
        email: formData.get('email'),
        first_name: formData.get('firstName'),
        last_name: formData.get('lastName'),
        password: formData.get('password'),
        company: formData.get('company'),
        country: (country as any)?.label,
        city: (city as any)?.name,
        zip: formData.get('zip'),
        street: formData.get('street'),
        street_no: formData.get('street_no'),
        phone: `${(phoneCode as any)?.phone}${formData.get('phone')}`,
      }),
    })
    .then(async (response) => {
      if (!response.ok) {
        const data = await response.json();
        if (data.message) {
          throw new Error(data.message);
        } else {
          throw new Error('Failed to send user data to webhook');
        }
      }
      (myFormRef as any)?.reset?.();
      setLoader(false);
      setAlertState({ open: true, severity: 'success', message: t('errors.success_register') });
    })
    .catch(error => {
      setLoader(false);
      console.log(error.message)
      if (errorExplanations.hasOwnProperty(error.message)) {
        setAlertState({ open: true, severity: 'error', message: errorExplanations[error.message] });
      } else {
        setAlertState({ open: true, severity: 'error', message: t('errors.unknown') });
      }
    })
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          {t('pages.sign_up.title')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} ref={(el) => setMyFormRef(el as any)}>
          {(loading || loader) && <LinearProgress style={{marginBottom: '20px'}} />}

          <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label={t('user_info.first_name')}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label={t('user_info.last_name')}
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="company"
                  label={t('user_info.company')}
                  name="company"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id="country"
                  fullWidth
                  options={allCountries}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  value={country}
                  onChange={handleCountryChange}
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} value={option.code}>
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        alt=""
                      />
                      {option.label} ({option.code}) +{option.phone}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('pages.sign_up.choose_country')}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id="city"
                  fullWidth
                  options={availableCities || []}
                  value={city}
                  onChange={(event, value: any) => setCity(value)}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} value={option.name}>
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => <TextField {...params} label={t('user_info.city')} />}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="zip"
                  label={t('user_info.zip')}
                  name="zip"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="street"
                  required
                  fullWidth
                  id="street"
                  label={t('user_info.street')}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="street_no"
                  label={t('user_info.street_no')}
                  name="street_no"
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <Autocomplete
                  id="phone_code"
                  fullWidth
                  options={allCountries}
                  autoHighlight
                  getOptionLabel={(option) => `+${option.phone}`}
                  value={phoneCode}
                  onChange={(event, value: any) => setPhoneCode(value)}
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} value={option.code}>
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        alt=""
                      />
                      ({option.code}) +{option.phone}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField
                  required
                  fullWidth
                  id="phone"
                  label={t('user_info.phone')}
                  name="phone"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label={t('user_info.email')}
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email2"
                  label={t('user_info.confirm_email')}
                  name="email2"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label={t('user_info.password')}
                  type="password"
                  id="password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password2"
                  label={t('user_info.confirm_password')}
                  type="password"
                  id="password2"
                />
              </Grid>
            </Grid>
          
          <FormControlLabel
            control={<Checkbox checked={termsAccepted} onChange={() => setTermsAccepted(!termsAccepted)} color="primary" />}
            label={
              <a target='_blank'  href="https://identaldesigner.nl/nl/terms-and-conditions/" rel="noreferrer">
                {t('pages.sign_up.terms')}
              </a>
            }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t('pages.sign_up.sign_up')}
          </Button>
          <Grid container>
            <Grid item xs>
            </Grid>
            <Grid item>
              <Link href="/" variant="body2">
                {t('pages.sign_up.already_account')}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {alertState.open && (
        <Alert severity={alertState.severity as AlertColor} onClose={() => setAlertState({ ...alertState, open: false })}>
          {alertState.message}
        </Alert>
      )}

      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}