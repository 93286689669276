import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import nl from "./translations/nl.json";
import en from "./translations/en.json";

i18n.use(initReactI18next).init({
  fallbackLng: "nl",
  interpolation: {
    escapeValue: false,
  },
  resources: {
        nl: {
            translation: nl,
        },
        en: {
            translation: en,
        },
    }
});

export default i18n;