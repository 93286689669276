import { createBrowserRouter } from "react-router-dom";

import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth/Signup";
import Dashboard from "./pages/Dashboard";
import MyAccount from "./pages/MyAccount";
import Forgot from "./pages/Auth/Forgot";
import ResetPassword from "./pages/Auth/ResetPassword";

const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/my-account",
      element: <MyAccount />,
    },
    {
      path: '/change-password',
      element: <Forgot />
    },
    {
      path: '/reset-password',
      element: <ResetPassword />
    }
]);

export default router;