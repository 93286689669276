import { createTheme, PaletteMode, ThemeProvider } from "@mui/material";
import { createContext, useEffect, useMemo, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { nlNL, enUS } from "@mui/material/locale";
import { LocalizationProvider } from "@mui/x-date-pickers";
import i18n from "../i18n";

export const MUIWrapperContext = createContext({
    toggleColorMode: () => {},
    toggleLanguage: () => {},
    language: "NL",
    countryFlag: "https://flagcdn.com/w40/nl.png",
});

export default function MUIWrapper({
    children,
}: {
    children: React.ReactNode;
}) {
    const [mode, setMode] = useState<PaletteMode>("light");
    const [language, setLanguage] = useState<string>(localStorage.getItem("language") || "nl");
    const [countryFlag, setCountryFlag] = useState('https://flagcdn.com/w40/nl.png')

    const countriesFlags = {
      en: { code: 'gb', name: 'English' },
      nl: { code: 'nl', name: 'Nederlands' }
    }

    useEffect(() => {
        i18n.changeLanguage(language);
        setCountryFlag(
            `https://flagcdn.com/w40/${(countriesFlags as any)[language].code}.png`
        );
    }, [language])

    const muiWrapperUtils = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
            }
        }),
        []
    );

    const theme = useMemo(
        () =>
        createTheme(
            {
                palette: {
                    mode,
                    primary: {
                        main: '#4581ff'
                    }
                },
            },
            language === "NL" ? nlNL : enUS
            ),
        [mode, language]
    );

    return (
        <MUIWrapperContext.Provider 
            value={{
                toggleColorMode: muiWrapperUtils.toggleColorMode,
                language,
                toggleLanguage: () => {
                    localStorage.setItem("language", language === "nl" ? "en" : "nl");
                    setLanguage((prevLanguage) => (prevLanguage === "nl" ? "en" : "nl"));
                },
                countryFlag
            }}
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </LocalizationProvider>
        </MUIWrapperContext.Provider>
    );
}