import React from 'react';
import './App.css';

import { ApolloProvider } from "@apollo/client";

import client from "./lib/apollo";
import { AuthProvider } from "./hooks/useAuth";
import { RouterProvider } from 'react-router-dom';
import router from './router';
import MUIWrapper from "./components/MUIWrapper";

import "./i18n";

function App() {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <MUIWrapper>
          <RouterProvider router={router} />
        </MUIWrapper>
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;
