import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

import DashboardLayout from '../layouts/DashboardLayout';
import useAuth from '../hooks/useAuth';
import router from '../router';
import { useTranslation } from 'react-i18next';

export default function MyAccount() {
  const [loader, setLoader] = React.useState(false);
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <DashboardLayout>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          {t('pages.my_account.account_information')}
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          {loader && <LinearProgress style={{marginBottom: '20px'}} />}

          <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstName"
                  fullWidth
                  id="firstName"
                  label={t('user_info.first_name')}
                  disabled
                  value={`${user?.firstName}`}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="lastName"
                  label={t('user_info.last_name')}
                  name="lastName"
                  disabled
                  value={`${user?.lastName}`}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="company"
                  label={t('user_info.company')}
                  name="company"
                  disabled
                  value={`${user?.userFields?.company}`}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="country"
                  label={t('user_info.country')}
                  fullWidth
                  disabled
                  value={`${user?.userFields?.country}`}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                    id="city"
                    label={t('user_info.city')}
                    fullWidth
                    disabled
                    value={`${user?.userFields?.city}`}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="zip"
                  label={t('user_info.zip')}
                  name="zip"
                  disabled
                  value={`${user?.userFields?.zip}`}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="street"
                  fullWidth
                  id="street"
                  label={t('user_info.street')}
                  disabled
                  value={`${user?.userFields?.street}`}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled
                  fullWidth
                  id="street_no"
                  label={t('user_info.street_no')}
                  name="street_no"
                  value={`${user?.userFields?.streetNo}`}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="phone"
                  label={t('user_info.phone')}
                  name="phone"
                  disabled
                  value={`${user?.userFields?.phone}`}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled
                  fullWidth
                  id="email"
                  label={t('user_info.email')}
                  name="email"
                  value={`${user?.email}`}
                />
              </Grid>
            </Grid>

            <Typography component="p" mt={3}>
              {t('pages.my_account.modify_request_1')}{' '}
              <a href='mailto:contact@identaldesigner.nl'>contact@identaldesigner.nl</a>{' '}
              {t('pages.my_account.modify_request_2')}
            </Typography>

            <Grid sx={{ mt: 3, mb: 3}} container>
                <Grid item xs={6} sm={6} style={{textAlign: "center"}}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => router.navigate('/dashboard')}
                    >
                        {t('pages.my_account.back')}
                    </Button>
                </Grid>
                <Grid 
                  item 
                  xs={6} 
                  sm={6} 
                  sx={{
                    textAlign: "center"
                  }}  
                >
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => router.navigate('/change-password')}
                    >
                        {t('pages.my_account.change_password')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
      </Box>
    </DashboardLayout>
  );
}