import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache
} from "@apollo/client";
import { API_URL } from "../utils/constants";

const link = ApolloLink.from([
    new HttpLink({
        uri: API_URL,
        useGETForQueries: true,
        credentials: "include"
    })
]);

const client = new ApolloClient({
    link,
    cache: new InMemoryCache()
});

export default client;