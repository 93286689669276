import React, { ReactNode } from "react";

import DashboardBar from "../components/DashboardBar";
import AuthContent from "../components/AuthContent";
import { Container } from "@mui/material";


export default function DashboardLayout({ children }: { children: ReactNode }) {
  return (
    <AuthContent>
        <DashboardBar />
        <Container maxWidth="xl" style={{marginTop: '50px'}}>
            {children}
        </Container>
    </AuthContent>
  );
}