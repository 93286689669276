import {
    Alert,
    Button,
    DialogProps,
    Grid,
    IconButton,
    Popover,
    Snackbar,
    TextField,
    Tooltip,
    TooltipProps,
    Typography,
    styled,
    tooltipClasses,
} from "@mui/material";
import { ViewInAr, Info, Download } from "@mui/icons-material";
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import { useFetch } from "../hooks/useFetch";
import { useEffect, useState } from "react";
import { Loader } from "./Loader";
import { OrderDialog } from "./OrderDialog";
import { FieldKeysList } from "../constants/OrderFields";
import React from "react";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { escapeRegExp } from "@mui/x-data-grid/utils/utils";
import { useTranslation } from "react-i18next";

const toHumanReadable = (str: string) => {
    return str.replace(/_/g, ' ')
              .replace(/\b\w/g, char => char.toUpperCase());
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }: any) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 500,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));


interface CRUDTableProps {
    baseUrl: string;
    columns: Array<{
        name: string;
        key: string;
        acf?: boolean;
    }>;
    title: string;
}

export function CRUDTable({ baseUrl, columns, title }: CRUDTableProps) {
	const { t } = useTranslation();

    const { data, isLoading, errorMessage, refetch } = useFetch(baseUrl);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [tableRows, setTableRows] = useState([]);
    const [snackbarData, setSnackbarData] = useState<any>({
        open: false,
        message: '',
        severity: 'success',
    });
    const [anchorInformationPopoverEl, setAnchorInformationPopoverEl] = useState<HTMLButtonElement | null>(null);
    const [popoverInformation, setPopoverInformation] = useState<string>('');
    const [popoverHtml, setPopoverHtml] = useState<any>({__html: ''});
    const [formData, setFormData] = useState<any>({});
    const [linkPreviewUrls, setLinkPreviewUrls] = useState<any>({});
    const [fileUrls, setFileUrls] = useState<any>({});
    const [searchText, setSearchText] = useState('');
    const [initialRows, setInitialRows] = useState<any>([]);

    const requestSearch = (searchValue: string) => {
        console.log(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = initialRows.filter((row: any) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]?.toString());
            });
        });
        setTableRows(filteredRows);
    };

    useEffect(() => {
        if (data) {

            const rows = (data as any)?.map((row: any, index: number) => {
                const date = new Date(row.date);

                if (date) {
                    //  show date to format DD.MM.YYYY - hh:mm(24h)
                    const hours = date.getHours();
                    const minutes = date.getMinutes();
                    const days = date.getDate();
                    const month = date.getMonth() + 1;

                    const time = `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;
                    const ddmmyyyy = `${days < 10 ? '0' + days : days}.${month < 10 ? '0' + month : month}.${date.getFullYear()}`

                    row.date = `${ddmmyyyy} - ${time}`;
                }

                if (row.acf.status) {
                    row.acf.status = t(`pages.dashboard.status_types.${String(row.acf.status)}`).toUpperCase();
                }

                return {
                    id: row.id,
                    ...row,
                    ...row.acf,
                }
            });

            setTableRows(rows);
            setInitialRows(rows);
            
            const newFilesUrls: any = {};
            (async () => {
                for (const row of (data as any)) {
                    const promises = row.acf?.files?.map(async (file: any) => {
                        return fetch('https://backend.identaldesigner.nl/wp-json/wp/v2/media/' + file)
                            .then(response => response.json())
                            .then(data => {
                                return data.guid.rendered;
                            })
                            .catch(error => { 
                                console.error('Error:', error);
                            });
                    });
    
                    if (!promises || promises.length === 0) return;
                    const values = await Promise.all(promises);
                    newFilesUrls[row.id] = values;
                    setFileUrls({...fileUrls, ...newFilesUrls});
                };
    
            })();

        }
    }, [data]);

    const tableColumns: GridColDef[] = [
        { 
            field: 'id', 
            headerName: t('pages.dashboard.id'), 
            flex: 1, 
            headerClassName: 'idd-table-header',
            headerAlign: 'center',
            align: 'center',
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => (
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Typography color="inherit">Order {params.value}</Typography>
                            <p>
                                <b>{t('pages.dashboard.submitted')}:</b> {params.row.date} <br/>
                                <b>Service Type:</b> {params.row.service_type} <br/>
                                <b>Order Type:</b> {params.row.order_type} <br/>
                                {FieldKeysList.map((field: any) => {
                                    const val = params?.row?.acf?.[field];
                                    if (val) {
                                        return (<>
                                            <b>{t(`services.labels.${field}`)}:</b>
                                            {' '}{val === 'true' ? t('yes') : val === 'false' ? t('no') : val} <br/>
                                        </>)
                                    }
                                })}
                                <b>Extra Info:</b> {params.row.extra_info} <br/>
                            </p>
                            <p>
                                <b>Attached files:</b> <br />
                                {fileUrls[params.row.id]?.map?.((file: any, index: number) => (
                                    <><a href={file} target="_blank" rel="noreferrer">File {index + 1}</a> <br/></>
                                ))}
                            </p>
                        </React.Fragment>
                    }
                >
                    <span
                        style={{
                            cursor: 'help',
                            fontWeight: 700,
                            color: '#4581ff',
                            textDecoration: 'underline'
                        }}
                    >
                        {params.value}
                    </span>
                </HtmlTooltip>
            )
        },
        ...columns.map(column => ({
            field: column.key,
            headerName: t(`pages.dashboard.${column.name.toLowerCase()}`),
            headerClassName: 'idd-table-header',
            align: 'center',
            headerAlign: 'center',
            flex: 2,
            ...(column.key !== 'order_type' && {
                filterable: false,
                disableColumnMenu: true,
            })
        }) as any),
        {
            field: 'actions',
            headerName: t('pages.dashboard.extra'),
            sortable: false,
            filterable: false,
            flex: 2,
            headerClassName: 'idd-table-header',
            headerAlign: 'center',
            align: 'center',
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => (
                <>
                    {(params as any)?.row?.final_html && (
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    {linkPreviewUrls[params.row.final_html]?.endsWith?.('.html') ? (
                                        <iframe 
                                            title="Preview" 
                                            src={linkPreviewUrls[params.row.final_html]}
                                            style={{width: '500px', height: '600px', border: 'none', overflow: 'hidden', borderRadius: '10px'}}
                                        ></iframe>
                                    ) : (
                                        <img
                                            src={linkPreviewUrls[params.row.final_html]}
                                            alt="Preview"
                                            style={{width: '500px', height: '600px', objectFit: 'contain'}}
                                        />
                                    )}
                                    
                                </React.Fragment>
                            }
                        >
                            <IconButton
                                onMouseEnter={() => {
                                    console.log(linkPreviewUrls[params.row.final_html]);
                                    if (linkPreviewUrls[params.row.final_html]) return;

                                    fetch(
                                        'https://backend.identaldesigner.nl/wp-json/wp/v2/media/' + (params as any)?.row?.final_html, 
                                        { redirect: 'follow'}
                                    )
                                        .then(response => response.json())
                                        .then(data => {
                                            setLinkPreviewUrls({...linkPreviewUrls, [(params as any)?.row?.final_html]: data.guid.rendered});
                                            
                                        })
                                        .catch(error => {
                                            console.error('Error:', error);
                                        });
                                }}
                                onClick={() => {
                                    window.open(linkPreviewUrls[params.row.final_html], '_blank');
                                }}
                            >
                                <ViewInAr />
                            </IconButton>
                        </HtmlTooltip>
                    )}
                    {(params as any)?.row?.acf?.final_info && (
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">{t('pages.dashboard.informations')}</Typography>
                                    <p>{(params as any)?.row?.acf?.final_info}</p>
                                </React.Fragment>
                            }
                        >
                            <IconButton
                                sx={{ cursor: 'help' }}
                            >
                                <Info />
                            </IconButton>
                        </HtmlTooltip>
                    )}
                    {(params as any)?.row?.final_file && (
                        <Button
                            variant="contained"
                            onClick={(event) => {
                                fetch('https://backend.identaldesigner.nl/wp-json/wp/v2/media/' + (params as any)?.row?.final_file)
                                    .then(response => response.json())
                                    .then(data => {
                                        window.open(data.guid.rendered, '_blank');
                                    })   
                            }}
                        >
                            Download
                        </Button>
                    )}

                </>
            )
        }
    ];

    const closeDialog: DialogProps["onClose"] = (event, reason) => {
        if (reason && reason === "backdropClick") 
            return;
        setIsEditDialogOpen(false);
        setSelectedRow(null);
        setFormData({});
        refetch();
    }

    return (
        <Loader
            isLoading={isLoading}
            height={600}
        >
            <Grid container alignItems="center" mb={3}>
                <Grid 
                    item 
                    xs={12} 
                    md={4}
                    display="flex"
                    alignItems="center"
                >
                    <Typography variant="h6">
                        {t('pages.dashboard.title')}
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => setIsEditDialogOpen(true)}
                        sx={{ ml: 2 }}
                    >
                        + {t('pages.dashboard.new_order')}
                    </Button>
                </Grid>
                <Grid 
                    item 
                    xs={12} 
                    md={8}
                    sx={{
                        mt: { xs: 2, md: 0 },
                    }}
                >
                    <TextField
                        variant="outlined"
                        value={searchText}
                        onChange={(e) => { setSearchText(e.target.value); requestSearch(e.target.value) }}
                        placeholder={t('pages.dashboard.search')}
                        InputProps={{
                            startAdornment: <SearchIcon fontSize="small" color="action" />,
                            endAdornment: (
                                <IconButton
                                    title="Clear"
                                    aria-label="Clear"
                                    size="small"
                                    style={{ visibility: searchText ? 'visible' : 'hidden', borderRadius: "57%", paddingRight: "1px", margin: "0", fontSize: "1.25rem" }}
                                    onClick={(e) => {setSearchText(''); setTableRows(initialRows)} }
                                >
                                    <ClearIcon fontSize="small" color="action" />
                                </IconButton>
                            ),
                        }}
                        sx={{
                            float: 'right',
                            width: { xs: 1, sm: 'auto' },
                            '& .MuiSvgIcon-root': {
                                mr: 0.5,
                            },
                            '& .MuiInput-underline:before': {
                                borderBottom: 1,
                                borderColor: 'divider',
                            },
                            '& .MuiInputBase-input': {
                                padding: 1
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <DataGrid
                rows={tableRows}
                columns={tableColumns}
                disableRowSelectionOnClick
                disableColumnSelector
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[5, 10, 15]}
                sx={{
                    boxShadow: 2,
                }}
            />

            <OrderDialog
                isEditDialogOpen={isEditDialogOpen}
                closeDialog={closeDialog}
                formData={formData}
                setFormData={setFormData}
                selectedRow={selectedRow}
            />

            <Snackbar
                open={snackbarData.open}
                onClose={() => setSnackbarData({open: false})} 
                autoHideDuration={4000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert severity={snackbarData.severity} sx={{ width: '100%' }} onClose={() => setSnackbarData({open: false})}>
                    {snackbarData.message}
                </Alert>
            </Snackbar>

            <Popover
                open={Boolean(anchorInformationPopoverEl)}
                anchorEl={anchorInformationPopoverEl}
                onClose={() => {
                    setAnchorInformationPopoverEl(null)
                    setPopoverInformation('')
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                    <Typography sx={{ p: 2 }}>{popoverInformation}</Typography>
                    <div dangerouslySetInnerHTML={popoverHtml} ></div>
            </Popover>
        </Loader>
    )
}
